.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*Custom css codes below */

.custom-gradiant {
  /* Force text color to white and ensure inheritance */
  color: white; /* Temporary !important to override conflicts */
  
  /* Fallback background */
  background: #fccb90;
  
  /* Gradient backgrounds */
  background: -webkit-linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
  background: linear-gradient(to right, #005269, #005269, #005269, #F8B615);
  
  /* Ensure text color inheritance */
  --bs-text-opacity: 1; /* Override Bootstrap variable if using Bootstrap */
}

/* Force all child elements to inherit color */
.custom-gradiant * {
  color: inherit; /* Use sparingly, only if necessary */
}

.custom-bg {
  background: #005269;
}

/* Sidebar custom css */

.sidebar {
  position: relative;
  z-index: 1050; /* Higher than the header */
}
